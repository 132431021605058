export default {
  data() {
    return {
      seaCountryList: [
        '4850', // Laos
        '5589', // Myanmar
        '7028', // Bangladesh
        '6057', // Sri Langka
        '4131', // Philipines
        '6458', // Brunei
        '7033', // Hong Kong
        '7043', // Taiwan
        '7041', // Koren
        '5527', // Mogolia
        '4635', // Cambodia
      ],
      malaysiaCountryCode: '5015',
      thaiCountryCode: '6163',
      ruCountryList: ['4266', '3983'], // Kazakhstan, Russia
    }
  },
  methods: {
    tncRedirect() {
      let domain = 'https://www.vantagemarkets.com/'
      const countryCode = this.$store.state.common.countryCode
      const middlePath = 'clients/promotions/'
      const depositBonus = '50percent-deposit-bonus'
      const claimYourBonus = 'claim-your-50-percent-bonus'
      const bonus = '50-percent-bonus'
      const campaign = this.$store.state.promotion.eligibleCampaigns.find(c => c.campaignType === 'DEPOSIT_BONUS_2')
      const campaignId = campaign.id
      if (campaignId === 7) {
        return this.urlBuilder(domain, middlePath, claimYourBonus)
      }
      if (campaignId === 6) {
        if (this.seaCountryList.includes(countryCode)) {
          domain = 'https://vantagemarkets.com/apac/'
          return this.urlBuilder(domain, middlePath, bonus)
        }
        if (countryCode === this.malaysiaCountryCode) {
          return this.urlBuilder(domain, 'ms-MY/', middlePath, bonus)
        }
        if (countryCode === this.thaiCountryCode) {
          return this.urlBuilder(domain, 'th-TH/', middlePath, bonus)
        }
        if (this.ruCountryList.includes(countryCode)) {
          return this.urlBuilder(domain, 'ru-RU/', middlePath, depositBonus)
        }
      }
    },
    urlBuilder(...args) {
      return args.join('')
    },
  },
}
