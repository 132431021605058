<template>
  <div class="optIn_dialog">
    <vDialog :visible.sync="visible" :visibleFooter="false" class="OptInPopup">
      <div class="optInPopupContent">
        <div class="body_title">{{ dialogContent.title }}</div>
        <div class="body_image" v-if="dialogContent.image">
          <img alt class="banner_image" :src="require(`@/assets/images/promotion/${dialogContent.image}`)" />
        </div>
        <div class="body_checkbox" v-if="dialogContent.checkbox">
          <el-checkbox
            :label="dialogContent.checkboxLabel"
            v-model="check"
            class="body_checkbox_label"
            data-testid="checkbox"
          ></el-checkbox>
        </div>
        <div class="btn_box">
          <el-button @click="optInClick" :disabled="!check && dialogContent.checkbox">
            {{ $t('promotion.depositBonus.optInButton') }}
          </el-button>
        </div>
        <div class="body_info" v-if="dialogContent.info">{{ dialogContent.info }}</div>
      </div>
    </vDialog>
  </div>
</template>

<script>
import vDialog from '@/components/home/vDialog';

export default {
  name: 'OptInDialog',
  props: {
    dialogContent: null,
    dialogVisible: Boolean
  },
  components: { vDialog },
  data() {
    return {
      visible: false,
      check: false
    };
  },
  watch: {
    dialogVisible(bool) {
      this.visible = bool;
    },
    visible(bool) {
      this.disableActivateVisible(bool);
    }
  },
  methods: {
    optInClick() {
      this.$emit('optInClick');
    },
    hideClick() {
      this.$emit('hideOptInPopup');
      this.disableActivateVisible(false);
    },
    disableActivateVisible(bool) {
      this.$emit('update:dialogVisible', bool);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/deposit/optInDialog.scss';
</style>
