import { apiQuery_paymentChannel } from '@/resource'
import idPoaMixin from '@/mixins/idPoa'
import { WHITELIST_AND_GREYLIST } from '@/bankInfo/IbtCountry'

export default {
  data() {
    return {
      mysCountryCode: 5015,
      idnCountryCode: 6877, // indonesia
      inCountryCode: 6845, // india
      ngaCountryCode: 5796,
      thaCountryCode: 6163,
      vnmCountryCode: 6991,
      phpCountryCode: 4131, // Philippines
      canCountryCode: 4575, // Canada
      ausCountryCode: 3512, // Australia
      krCountryCode: 7041,
      chCountryCode: 1, // china
      brlCountryCode: 3636, // Brazil
      ukCountryCode: 6907, // United Kingdom
      zarCountryCode: 5696, // South Africa
      laosCountryCode: 4850, // Laos
      jpnCountryCode: 5860, // Japan
      hkCountryCode: 7033, //Hong Kong,
      ugandaCountryCode: 6459, // Uganda
      rwandaCountryCode: 4917, // Rwanda
      zambiaCountryCode: 6996, // Zambia
      congoCountryCode: 7031, // Congo
      cameroonCountryCode: 4688, // Cameroon
      burundiCountryCode: 3865, // Burundi
      kenyaCountryCode: 4759, // Kenya
      ghanaCountryCode: 4611, // Ghana
      tanzaniaCountryCode: 7089, // Tanzania
      mexicoCountryCode: 5619, // Mexico
      colombiaCountryCode: 4186, // Colombia
      chileCountryCode: 7010, // Chile
      peruCountryCode: 5558, // Peru
      IranCountryCode: 6766, // Iran
      EEACountriesList: [
        6907, // United Kingdom
        7048, // Aland Islands
        3554, // Austria
        3701, // Belgium
        3674, // Bulgaria
        7057, // Croatia
        6016, // Cyprus
        4661, // Czech Republic
        3899, // Denmark
        3464, // Estonia
        4138, // Finland
        4101, // France
        7059, // French Guiana
        7062, // Guadeloupe
        7070, // Martinique
        7071, // Mayotte
        7080, // Reunion
        3915, // Germany
        7063, // Guernsey
        7061, // Gibraltar
        6633, // Greece
        6673, // Hungary
        3436, // Ireland
        6777, // Italy
        4810, // Latvia
        4910, // Liechtenstein
        4897, // Lithuania
        4912, // Luxembourg
        5009, // Malta
        4482, // Netherlands
        5804, // Norway
        3716, // Poland
        5828, // Portugal
        4935, // Romania
        7087, // Svalbard and Jan Mayen
        6084, // Slovakia
        6094, // Slovenia
        6581, // Spain
        5909, // Sweden
      ],
      //Kazakhstan, Russia, Vietnam, Latvia
      netellerCountriesList: [4266, 3983, 6991, 4810],
      // Vietnam, Cambodia
      vnmCountryList: [6991, 4635],
      // Armenia, Azerbaijan, Belarus, Georgia, Kazakhstan, Kyrgyzstan, Latvia, Lithuania, Moldova, Russia, Tajikistan, Turkmenistan, Uzbekistan, Vietnam
      webMoneyCountryList: [6729, 3399, 3665, 5859, 4266, 4546, 4810, 4897, 5605, 3983, 6146, 6385, 6565, 6991],
    }
  },
  mixins: [idPoaMixin],
  mounted() {
    this.$store.dispatch('payment/actionRestrictDeposit')
  },
  methods: {
    query_paymentChannel() {
      apiQuery_paymentChannel()
        .then(resp => {
          if (resp.data.code == 0) {
            if (resp.data.data == 1) {
              this.$router.push({ name: 'thailandZotaPay' })
            } else if (resp.data.data == 2) {
              this.$router.push({ name: 'thailandPaytoday' })
            } else if (resp.data.data == 3) {
              this.$router.push({ name: 'mijiPay' })
            } else if (resp.data.data == 4) {
              this.$router.push({ name: 'thailandEeziePay' })
            } else if (resp.data.data == 5) {
              this.$router.push({ name: 'thailandXpay' })
            }
          }
        })
        .catch(err => {
          this.$message({ message: 'Fetch payment channel failed.', type: 'error' })
        })
    },
    notRestricted(type, channel = 1) {
      return this.restrictedDeposit === 0 ? true : this.checkRestricted(type, channel)
    },
    checkRestricted(type, channel) {
      let restricted = false
      this.restrictedDeposit.forEach(function (x, y) {
        if (x.paymentType === type && x.paymentChannel === channel) {
          restricted = true
        }
      })

      return !restricted
    },
    zotaClick() {
      if (this.countryCode == this.idnCountryCode) {
        this.$router.push({ name: 'indonesiaPayment' })
      } else if (this.countryCode == this.phpCountryCode) {
        this.$router.push({ name: 'philipinessPayment' })
      } else if (this.countryCode == this.vnmCountryCode) {
        this.$router.push({ name: 'vietnamPayment' })
      } else if (this.countryCode == this.thaCountryCode) {
        this.query_paymentChannel()
      } else if (this.countryCode == this.ngaCountryCode) {
        this.$router.push({ name: 'nigeriaZotapay' })
      }
    },
    eeziePayClick() {
      if (this.countryCode == this.mysCountryCode) {
        this.$router.push({ name: 'malaysiaEeziePay' })
      } else if (this.countryCode == this.vnmCountryCode) {
        this.$router.push({ name: 'vietnamEeziePay' })
      } else if (this.countryCode == this.idnCountryCode) {
        this.$router.push({ name: 'indonesiaEeziePay' })
      } else if (this.countryCode == this.inCountryCode) {
        this.$router.push({ name: 'indiaEeziePay' })
      }
    },
    creditClick() {
      this.$router.push({ name: 'creditOrDebit' })
    },
    bitwalletClick() {
      this.$router.push({ name: 'bitwalletDeposit' })
    },
    bridgePaymentClick() {
      this.$router.push({ name: 'bridgePaymentDeposit' })
    },
    internationalClick() {
      this.$router.push({ name: 'internationalSwift' })
    },
    unionClick() {
      this.$router.push({ name: 'unionPay' })
    },
    bpayClick() {
      this.$router.push({ name: 'bPayDeposit' })
    },
    poliClick() {
      this.$router.push({ name: 'poliPay' })
    },
    skrillClick() {
      this.$router.push({ name: 'skrillPay' })
    },
    sticPayClick() {
      this.$router.push({ name: 'sticPay' })
    },
    webMoneyClick() {
      this.$router.push({ name: 'webMoney' })
    },
    netellerClick() {
      this.$router.push({ name: 'neteller' })
    },
    brokerClick() {
      this.$router.push({ name: 'brokerToBrokerTransfer' })
    },
    fasapayClick() {
      this.$router.push({ name: 'fasaPay' })
    },
    cryptoClick() {
      this.$router.push({ name: 'crypto' })
    },
    indiaClick() {
      this.$router.push({ name: 'indiaPayment' })
    },
    pagsmileClick() {
      this.$router.push({ name: 'pagsmile' })
    },
    indiaBankTransferClick() {
      this.$router.push({ name: 'indiaBankTransfer' })
    },
    astropayClick() {
      this.$router.push({ name: 'astropayDeposit' })
    },
    southAfricaClick() {
      this.$router.push({ name: 'southAfricaPayment' })
    },
    ukClick() {
      this.$router.push({ name: 'uk' })
    },
    vloadClick() {
      this.$router.push({ name: 'vload' })
    },
    laosClick() {
      this.$router.push({ name: 'laos' })
    },
    dasshpeUPIClick() {
      this.$router.push({ name: 'dasshpeUPI' })
    },
    dasshpeWalletClick() {
      this.$router.push({ name: 'dasshpeWallet' })
    },
    vietnamClick() {
      this.$router.push({ name: 'vietnamPayment' })
    },
    hongkongClick() {
      this.$router.push({ name: 'hongkong' })
    },
    hongkongQRClick() {
      this.$router.push({ name: 'hongkongQR' })
    },
    africaClick() {
      this.$router.push({ name: 'africaZotapay' })
    },
  },
  computed: {
    countryCode() {
      return parseInt(this.$store.state.common.countryCode)
    },
    showIBT() {
      return WHITELIST_AND_GREYLIST.includes(this.countryCode)
    },
    excludeEEACountries() {
      return !this.EEACountriesList.includes(this.countryCode)
    },
    showVietnam() {
      return this.vnmCountryList.includes(parseInt(this.countryCode))
    },
    showWebMoney() {
      return this.webMoneyCountryList.includes(parseInt(this.countryCode))
    },
    excludeNetellerCountries() {
      return !this.netellerCountriesList.includes(this.countryCode)
    },
    excludeIndia() {
      return this.countryCode !== this.inCountryCode
    },
    excludeChina() {
      return this.countryCode !== this.chCountryCode
    },
    excludeIran() {
      return this.countryCode !== this.IranCountryCode
    },
    langPreferred() {
      if (this.$store.state.common.lang === 'th') {
        return require(`@/assets/images/preferred_thai.png`)
      } else {
        return require(`@/assets/images/preferred.png`)
      }
    },
    idPass() {
      return !this.requireIDProof && !this.pendingIDProofApproval
    },
    restrictedDeposit() {
      return this.$store.state.payment.restrictedDeposit
    },
  },
}
