/**
 * For CPS Deposit Listing Ordering
 * (CPS Module)
 */

import { countryCodeEnum } from '@/constants/country'

export const cpsDepositListingIndex = [
  {
    id: countryCodeEnum.THAILAND,
    order: ['GTHT00600', 'T00312_021', 'T00100_045', 'GT00400'],
  },
  {
    id: countryCodeEnum.INDONESIA,
    order: ['T00600_051', 'T00100_045', 'GT00400'],
  },
  {
    id: countryCodeEnum.VIETNAM,
    order: ['T00312_020', 'T00100_045', 'GT00400'],
  },
  {
    id: countryCodeEnum.PHILIPPINES,
    order: ['T00600_053', 'T00100_045', 'GT00400'],
  },
  {
    id: countryCodeEnum.BRAZIL,
    order: ['T00312_033', 'GT00400', 'T00600_054', 'T00600', 'T00100_045'],
  },
  {
    id: countryCodeEnum.CHINA,
    order: ['T00312', 'GCNT00600'],
  },
  {
    id: countryCodeEnum.IRAN,
    order: ['T00100_069'],
  },
  {
    id: 'global',
    order: ['T00100_045', 'GT00400'],
  },
]
